.spinner {
    left: 50%;
    top: 50%;
    height:60px;
    width:60px;
    margin:0px auto;
    -webkit-animation: rotation .6s infinite linear;
    -moz-animation: rotation .6s infinite linear;
    -o-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
    border-left:6px solid rgba(0,174,239,.15);
    border-right:6px solid rgba(0,174,239,.15);
    border-bottom:6px solid rgba(0,174,239,.15);
    border-top:6px solid rgba(0,174,239,.8);
    border-radius:100%;
}

@-webkit-keyframes rotation {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
    from {-moz-transform: rotate(0deg);}
    to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
    from {-o-transform: rotate(0deg);}
    to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
}

.container {
    font-family: 'Raleway';
}

.container-fluid {
    font-family: 'Raleway';
}

.EvColor{
    background-color: #FF2800 !important;
    color: #FFF;
}

.EvColorGris{
    background-color: #C6CCD4 !important;
}

.EvColorLightBlue {
    background-color: #212F3D !important;
}

.EvColorBlue {
    background-color: #0B1224 !important;
}

.EvColorGrisLight{
    background-color: #f1f1f1;
}

.logo {
    max-height: 150px;
}

.logoEV {
    max-height: 45px;
    margin: 10px;
}

.my-m4 {
    margin: 1.5rem !important;
}

.boton,
.boton:active,
.boton:focus {
    background-color: #202D3B;
    display: inline-block;
    padding: 14px;
    color: #fff;
    width: 200px;
    border-radius: 10px;
    font-family: 'Raleway', sans-serif;
    font-size: 2.3em;
    font-weight: 100;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.boton.resultados {
    font-size: 1.5em;
}

.boton.open:hover {
    background-color: #EA4225;
    color: #fff;
    text-decoration: none;
}


.boton.ready span {
    background-color: #C6CED6;
    width: 100%;
    font-size: 0.5em;
    display: block;
}



.boton span.iconvote {
    background: url('/img/vote.svg') no-repeat;
    float:left;
    width: 40px;
    height: 40px;
}

.boton-download,
.boton-download:active,
.boton-download:focus {
    background-color: #bac4ce;
    display: inline-block;
    padding: 14px;
    color: #fff;
    width: 40%;
    border-radius: 10px;
    font-family: 'Raleway', sans-serif;
    font-size: 1em;
    font-weight: 100;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.boton-download span.icondownload {
    background: url('/img/download.svg') no-repeat;
    float:left;
    width: 20px;
    height: 20px;
}

.comienza, .finaliza {
    display: block;
    width: 350px;
    position: relative;
    bottom: inherit;
    left: inherit;
    float: left;
    margin-top: -140px;
}

.comienza{
    margin-top: -140px;
}

.finaliza {
    margin-top: -100px;
}

.finaliza p, comienza p{
    font-family: 'Raleway', sans-serif;
    font-size: 1em;
    color: #000;
    margin: 0 0 10px 0;
}

.finaliza p + p {
    background: #C6CED6;
    color: #071123;
    padding: 10px;
    font-size: 1.3em;
    border-radius: 10px;
    font-weight: 900;
}

.como-title {
    color: #EA4225;
    font-size: 1.75em;
    font-weight: bold;
}

.dudas {
    padding: 30px;
}

.sabermas {
    padding: 30px;
}


.sabermas a {
    color: #fff;
}


.sabermas a:hover {
    color: #fff;
    text-decoration: none;
}

.dudas-box {
    border-right: 1px solid #333;
}

.linespanblack {
    display: none;
}

.linespanwhite {
    display: none;
}

.vp-75 {
    height: 75px;
}

.img-max-200 {
    max-width: 200px;
    width:100%;
}

.img-max-300 {
    max-width: 300px;
    width:100%;
}

.img-max-100 {
    max-width: 100px;
    width:100px;
    height: 100px;
}

.sabermas-box {
    border-right: 1px solid #fff;
}

.btn-wsp {
    display: inline-block !important;
    padding: 14px !important;
    color: #000 !important;
    border-radius: 10px !important;
    font-family: Raleway,sans-serif;
    font-size: 1em !important;
    font-weight: 100 !important;
    text-align: center !important;
    background-color: #FFFFFF !important;
    border: 2px solid #1c7430 !important;
}

.img-wsp {
    width: 30px;
}

.mesa-ayuda-img{
    justify-content: end;
}

.mesa-ayuda-content > p > a{
    color: #FFF !important;
}

@media (min-width: 320px) and (max-width: 940px) {
    .mesa-ayuda{
        font-size: 13px;
    }
    .mesa-ayuda-content{
        padding: 0 !important;
        flex-direction: column !important;
    }
    .mesa-ayuda-img{
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
    .mesa-ayuda-content > p {
        width: auto;
    }
    .finaliza, .comienza {
        width: 100%;
        float: none;
        margin: 0;
        text-align: center;
    }

    .finaliza p + p {
        width: 50%;
        margin: 0 auto;
    }

    .linespanblack {
        border-bottom: 2px solid #000;
        width: 60%;
        margin: 30px auto 0;
        display: block;
    }

    .linespanwhite {
        border-bottom: 2px solid #fff;
        width: 100%;
        margin-top: 30px;
        display: block;
    }

    .comovotar {
        margin-top: 1.5rem !important;
        background-color: #202D3B;
    }

    .dudas-box {
        border: none;
    }

    .sabermas-box {
        border: none;
    }

    .vp-75 {
        height: auto;
    }

    .my-m4 {
        margin: 0 !important;
    }
}